import React from "react";

export default function Fallback() {
  return (
    <div className="relative w-screen h-screen flex items-center bg-blue animate-pulse">
      <div className="fixed bg-blue top-0 left-0 w-full screen-padding py-2 h-[90px] shadow-lg animate-pulse flex justify-between items-center">
        <div className="w-[200px] lg:w-[350px] h-full rounded-md bg-gray-100 animate-pulse"></div>
        <div className="hidden w-[100px] h-[30px] rounded-md bg-gray-100 animate-pulse lg:inline"></div>
        <div className="hidden w-[100px] h-[30px] rounded-md bg-gray-100 animate-pulse lg:inline"></div>
        <div className="hidden w-[100px] h-[30px] rounded-md bg-gray-100 animate-pulse lg:inline"></div>
        <div className="hidden w-[100px] h-[30px] rounded-md bg-gray-100 animate-pulse lg:inline"></div>
        <div className="hidden w-[100px] h-[30px] rounded-md bg-gray-100 animate-pulse lg:inline"></div>
        <div className="w-[50px] lg:w-[100px] h-[50px] rounded-md bg-gray-100 animate-pulse"></div>
        <div className="hidden w-[50px] h-[50px] rounded-full bg-gray-100 animate-pulse lg:inline"></div>
      </div>
      <div className="w-full h-full screen-padding flex flex-col gap-8 md:flex-row items-center justify-center pt-56">
        <div className="w-full md:w-1/2 h-full flex flex-col gap-4">
          <div className="w-full h-4 bg-gray-100 animate-pulse rounded-md py-8"></div>
          <div className="w-full h-20 md:h-40 bg-gray-100 animate-pulse rounded-md py-8"></div>
        </div>
        <div className="w-full md:w-1/2 h-full flex flex-col">
          <div className="w-full h-40 md:h-60 rounded-lg bg-gray-100 animate-pulse rounded-md"></div>
        </div>
      </div>
    </div>
  );
}

import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "../Pages/NotFound";
import Fallback from "../Pages/Fallback";

const App = () => {
  const Home = React.lazy(() => import("../Pages/Home"));
  const CPanel = React.lazy(() => import("../Pages/CPanelCloudHosting"));
  const DomainReg = React.lazy(() => import("../Pages/DomainRegistration"));
  const EmailHosting = React.lazy(() => import("../Pages/EmailHosting"));
  const SSL = React.lazy(() => import("../Pages/SSL"));
  const AboutUs = React.lazy(() => import("../Pages/AboutUs"));
  const Zoho = React.lazy(() => import("../Pages/Zoho"));
  const Affiliate = React.lazy(() => import("../Pages/Affiliate"));
  const DirectAdminHosting = React.lazy(() =>
    import("../Pages/DirectAdminHosting")
  );
  const DomainTransfer = React.lazy(() => import("../Pages/DomainTransfer"));
  const PrivacyPolicy = React.lazy(() => import("../Pages/PrivacyPolicy"));
  const TermsofService = React.lazy(() => import("../Pages/TermsofService"));
  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route index path="/cpanel-web-hosting" element={<CPanel />} />
          <Route index path="/domain-registration" element={<DomainReg />} />
          <Route index path="/email-hosting" element={<EmailHosting />} />
          <Route index path="/ssl-certificates" element={<SSL />} />
          <Route index path="/about-us" element={<AboutUs />} />
          <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route index path="/tos" element={<TermsofService />} />
          <Route index path="/zoho-workplace" element={<Zoho />} />
          <Route
            index
            path="/web-hosting-affiliate-program"
            element={<Affiliate />}
          />
          <Route
            index
            path="/directadmin-hosting"
            element={<DirectAdminHosting />}
          />
          <Route index path="/domain-transfer" element={<DomainTransfer />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
